import CheckIcon from "@mui/icons-material/Check";
import { ReactNode } from "react";
import RepeatIcon from '@mui/icons-material/Repeat'; // import { visuallyHidden } from '@mui/utils';
import HandymanIcon from '@mui/icons-material/Handyman';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import SwipeIcon from '@mui/icons-material/Swipe';
import WashIcon from '@mui/icons-material/Wash';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import GetAppIcon from '@mui/icons-material/GetApp';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material';

export const CSTE_ROUTES = {
  COMMONS: {
    INDEX: '/',
    LOGIN: '/',
    SSO_AUTHENTICATED: '/sso-authenticated',
    RELOGIN: '/relogin',
    APPSELECTOR: '/app-selector',
    FORBIDDEN: '/status/500',
    NOTIFICATION: {
      CREATE: '/%MODULE%/notification/create',
      LIST: '/%MODULE%/notification/list',
    },
  },
  ADMINISTRATOR: {
    INDEX: '/',
    LOGIN: '/',
    OVERVIEW: '/administrator/overview',
    USERS: {
      LIST: '/administrator/users',
      EDIT: '/administrator/users/edit',
    },
    PROFILES: {
      MAINPAGE: '/administrator/accounts-permissions',
      EDIT: '/administrator/accounts-permissions/edit',
    },

    DATAEXPLORER: '/administrator/data-explorer',
    DEVICES: {
      LIST: '/administrator/devices',
    },
    DOCUMENTS: {
      LIST: '/administrator/documents',
    },
    HISTORY: {
      LIST: '/administrator/history',
    },
    UPLOADSCMM: {
      UPLOADLIST: '/administrator/uploadcmm',
      UPLOADSUBMIT: '/administrator/uploadcmm/submit',
      UPLOADDETAIL: '/administrator/uploadcmm/detail',
    },
    NOTIFICATION: {
      CREATE: '/administrator/notification/create',
      LIST: '/administrator/notification/list',
    },
  },
  WEBREPORTING: {
    INDEX: '/',
    LOGIN: '/',
    OVERVIEW: '/webreporting/overview',
    DATAEXPLORER: {
      INDEX: '/webreporting/data-explorer',
    },
    SEARCH_PN: {
      INDEX: '/webreporting/search-pn',
    },
    MAINTENANCE: {
      INDEX: '/webreporting/maintenance',
    },
    REPORTS: {
      RFIDINVENTORIES: '/webreporting/reports/rfid-inventories',
      WEEKLYREPORT: '/webreporting/reports/weekly-report',
    },
    REQUESTSVIEWER: '/webreporting/dashboard/requestsViewer',
    FLEET: {
      AIRCRAFTLIST: '/webreporting/fleet/aircraftlist',
    },
  },
  CONFIGURATOR: {
    INDEX: '/',
    LOGIN: '/',
    OVERVIEW: '/configurator/overview',
    DATAEXPLORER: {
      INDEX: '/webreporting/data-explorer',
    },
    NOTIFICATION: {
      CREATE: '/configurator/notification/create',
      LIST: '/configurator/notification/list',
    },
    TABLES: {
      LIST: '/configurator/tables/list',
      AIRCRAFT: '/configurator/tables/aircraft',
      KPIS: '/configurator/tables/kpis',
      NARROW_WIDE_BODIES: '/configurator/tables/narrow-wide-bodies',
    },
    GENERALSETTINGS: {
      INDEX: '/configurator/general-settings',
    },
    INTEROPS: {
      INDEX: '/configurator/customer-interop',
      EDIT: '/configurator/customer-interop/edit',
    },
    DISPATCHER: {
      ENVS: {
        INDEX: 'dispatcher/envs',
      },
      ENVVARS: {INDEX: 'dispatcher/environmentvariables'},
    },
    THEME: {
      SETTINGS: '/configurator/theme/settings',
    },
    SSO: {
      SETTINGS: '/configurator/sso/settings',
    },
    DATABASE: {
      INDEX: '/configurator/database',
    },
    PROFILES: {
      MAINPAGE: '/configurator/accounts-permissions',
      EDIT: '/configurator/accounts-permissions/edit',
    },
  },
};

export const CSTE_PERSMISSIONS = {
  configurator: {
    name: 'configurator',
    applicationSection: 'webreportingV3',
    type: 'module',
  },
  administrator: {
    name: 'administrator',
    applicationSection: 'webreportingV3',
    type: 'module',
  },
  webreporting: {
    name: 'webreporting',
    applicationSection: 'webreportingV3',
    type: 'module',
  },
  '/webreporting/reports/rfid-inventories': {
    name: 'RFID_inventories',
    applicationSection: 'webreportingV3',
    type: 'feature',
  },
  'non-recurring-card': {
    name: 'non-recurring-card',
    applicationSection: 'webreportingV3',
    type: 'feature',
  },
  editUsers: {
    name: 'editUsers',
    applicationSection: 'admin',
    type: 'widget',
  },
  '/administrator/users/edit': {
    name: 'editUsers',
    applicationSection: 'admin',
    type: 'widget',
  },
  '/webreporting/fleet/aircraftlist': {
    name: 'fleet-aircraftlist',
    applicationSection: 'webreportingV3',
    type: 'feature',
  },
  '/webreporting/reports/weekly-report': {
    name: 'report-weeklyreport',
    applicationSection: 'webreportingV3',
    type: 'feature',
  },
  '/webreporting/search-pn': {
    name: 'searchpn-mainpage',
    applicationSection: 'webreportingV3',
    type: 'feature',
  },
};

// searchpn-mainpage

export const CSTE_LABELS = {
  COMMONS: {
    AIS_COMPANY_NAME: 'AirInt Services',
    AIS_SOFTWARE_TITLE: 'Cabin Portal',
    AIS_SOFTWARE_SUBTITLE: '',
    AIS_SOFTWARE_SLOGAN: 'Your solution for supervising your cabin maintenance',
  },
  ADMINISTRATION: {
    APPTITLE: 'Global Settings',
    DESCRIPTION:
      'This module will allow you to administer your users, your user profiles, your permissions as well as some basic tables.',
  },
  WEBREPORTING: {
    APPTITLE: 'Dashboard & Analysis',
    DESCRIPTION:
      'This module will allow you to visualize Kpi, dashboards in order to facilitate the supervision of your activity',
  },
  AIRINTADMIN: {
    APPTITLE: 'Advanced Settings',
    DESCRIPTION:
      "This module is strictly reserved for AIRINT users. It will be used to set up and configure your Cabin'app environment.",
  },
};

export const CSTE_CUSTOMER_INTEROP = {
  FILETERING_FIELDS: [
    {id: '${Request.UserId}', name: 'Filter by User'},
    {id: '${Request.AircraftId}', name: 'Filter by Aircraft'},
    {
      id: '${Request.AircraftId}_${Request.UserId}',
      name: 'Filter by Aircraft and User',
    },
    {
      id: '${Request.Aircraft.type}_${Request.FamilyId}',
      name: 'Filter by Aircraft type and Family Equipment',
    },
    {
      id: '${Request.LopaEquipmentSN.LopaEquipment.Equipment.lettering}',
      name: 'Filter by Request Equipment lettering',
    },
    {
      id: '${Request.UserId}_#BEGINUSEONCE#${Request.RequestInterops[CustomerInteropId = 66].ReportId}#ENDUSEONCE#',
      name: 'Filter by User and Report Id for a specific Customer Interop.',
    },
  ],
  SCOPE_VALUES: [
    {id: 'all', name: 'Cabin APP & web reporting'},
    {id: 'no', name: 'Hidden'},
    {id: 'web', name: 'Web Reporting'},
  ],
  TYPE_VALUES: [
    {id: 'free', name: 'Open field (free)'},
    {id: 'list', name: 'List'},
    {id: 'autocomplete', name: 'List (auto-complete)'},
    {id: 'listduo', name: 'List DUO (parent,child)'},
    {id: 'custom', name: 'Custom'},
    {id: 'form', name: 'Form'},
    {id: 'link', name: 'Link'},
  ],
  TYPE_VALUES_FORMS: [
    {id: 'free', name: 'Open field (free)'},
    {id: 'list', name: 'List'},
    {id: 'autocomplete', name: 'List ( auto complete)'},
    {id: 'listduo', name: 'List DUO (parent,child)'},
    {id: 'custom', name: 'Custom'},
    {id: 'photo', name: 'Photo'},
    {id: 'date', name: 'Date'},
    {id: 'section', name: 'Section'},
  ],
  MANDATORY_VALUES: [
    {id: 0, name: '0 - Optional'},
    {id: 1, name: '1 - Mandatory'},
    {id: 2, name: '2 - Conditional'},
  ],
  KEYBOARDTYPE: [
    {id: 'default', name: 'default'},
    {id: 'number-pad', name: 'number-pad'},
    {id: 'decimal-pad', name: 'decimal-pad'},
    {id: 'numeric', name: 'numeric'},
    {id: 'email-address', name: 'email-address'},
    {id: 'phone-pad', name: 'phone-pad'},
    {id: 'url', name: 'url'},
    {id: 'ascii-capable', name: 'ascii-capable ( iOS only )'},
    {
      id: 'numbers-and-punctuation',
      name: 'numbers-and-punctuation ( iOS only )',
    },
    {id: 'name-phone-pad', name: 'name-phone-pad ( iOS only )'},
    {id: 'twitter', name: 'twitter ( iOS only )'},
    {id: 'web-search', name: 'web-search ( iOS only )'},
  ],
  AUTOCAPILIZETVALUES: [
    {id: 'characters', name: 'characters: all characters'},
    {id: 'words', name: 'words: first letter of each word'},
    {
      id: 'sentences',
      name: 'sentences: first letter of each sentence (default)',
    },
    {id: 'none', name: "none: don't auto capitalize anything"},
  ],
  PATTERNLIST: [
    {id: '[^0-9]', name: '[^0-9] - You can only enter numbers'},
    {id: '[^A-Z]', name: '[^A-Z] - You can only enter letters'},
    {
      id: '[^0-9A-Z]',
      name: '[^0-9A-Z] - You can only enter numbers and letters in uppercase',
    },
  ],
};

export const CSTE_MAINTENANCE = {
  OPTIONS_PERIODE_PERIMETER: [
    {id: 'all', name: 'All data'},
    {id: 'h-24', name: '24 hours'},
    {id: 'h-48', name: '48 hours'},
    {id: 'd-7', name: '7 days'},
    {id: 'd-14', name: '14 days'},
    {id: 'm-1', name: 'month'},
    {id: 'm-2', name: '2 months'},
    {id: 'm-3', name: '3 months'},
    {id: 'm-6', name: '6 months'},
    {id: 'm-12', name: '12 months'},
    {id: 'm-24', name: '24 months'},
  ],
};

export const CSTE_PRIORITY_ICONS = {
  getIcone: (priorityIcon: string, sx: SxProps<Theme> = {fontSize: 17}): ReactNode => {
    switch (priorityIcon) {
      case 'icon-switch': {
        return <RepeatIcon sx={sx} />;
        break;
      }

      case 'icon-tools': {
        return <HandymanIcon sx={sx} />;
        break;
      }

      case 'icon-right-hand': {
        return <SwipeIcon sx={sx} />;
        break;
      }

      case 'icon-water': {
        return <WashIcon sx={sx} />;
        break;
      }

      case 'icon-random': {
        return <SwapHorizIcon sx={sx} />;
        break;
      }

      case 'icon-down-big': {
        return <GetAppIcon sx={sx} />;
        break;
      }

      case 'icon-search': {
        return <FindReplaceIcon sx={sx} />;
        break;
      }

      case 'icon-check': {
        return <CheckIcon sx={sx} />;
        break;
      }

      case 'icon-to-be-checked': {
        return <PublishedWithChangesIcon sx={sx} />;
        break;
      }

      case 'icon-safety': {
        return <HealthAndSafetyIcon sx={sx} />;
        break;
      }

      case 'icon-target': {
        return <TouchAppIcon sx={sx} />;
        break;
      }

      default: {
        return <NotInterestedIcon sx={sx} />;
        break;
      }
    }
  },
};

export const CSTE_SSO_LOGIN = {
  ely: {
    image: '/static/images/logo/logo-ely.png',
    label: 'Login with EL AL',
  },
  iberia: {
    image: '/static/images/logo/logo-iberia.png',
    label: 'Login with Iberia',
  },
  saudia: {
    image: '/static/images/logo/logo-saudia.png',
    label: 'Login with Saudia',
  },
  habile: {
    image: '/static/images/logo/logo-afr.png',
    label: 'Login with Habile',
  },
};

export const AvailableKpis = [
  {code: 'KPI03', size: 12},
  {code: 'KPI01', size: 6},
  {code: 'KPI02', size: 6},
  {code: 'KPI04', size: 6},
  {code: 'KPI05', size: 6},
  {code: 'KPI06', size: 6},
  {code: 'KPI07', size: 6},
  {code: 'KPI08', size: 12},
  {code: 'KPI09', size: 6},
  {code: 'KPI10', size: 12},
  {code: 'KPI11', size: 12},
];
