/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/always-return */
/* eslint-disable max-classes-per-file */
import {AxiosError, AxiosResponse} from 'axios';
import {envsAttributes} from 'monorepo-db/models/dispatcher/mysql/envs';
import {pinvaluesAttributes} from 'monorepo-db/models/dispatcher/mysql/pinvalues';
import {CustomerAttributes} from 'monorepo-db/models/cabin_app/mysql/Customer';

import {ICriteria} from 'src/components/web-reporting/maintenance';
import {IfieldsToUpdate} from 'src/components/web-reporting/request/editRequests';
import {cabinAppTheme} from 'src/pages/app-configurator/theme/manageTheme';
import {IConfigSSO} from 'monorepo-shared/interfaces/interfaceCabinPortal/settings';
import {KpiOptions, KpiOptionsAttributes} from 'monorepo-db/models/cabin_app/mysql/KpiOptions';
import {DataMappingAttributes} from 'monorepo-db/models/cabin_app/mysql/DataMapping';
import {AISCacheService} from './cacheService';
import {HeadCell} from '../components/Tables/enhancedTable';
import api, {apiDispatcher, ENDPOINTS} from './api';
import {APIResponse} from '../models/commons';
import {Aircraft} from '../models/tables';

interface MoreParam {
  name: string;
  value: string;
}

export type AISPromiseResponse = Promise<AxiosResponse<APIResponse, APIResponse>>;

class AISDataService {
  private additionalsParams: Array<MoreParam> = [
    {name: 'udid', value: 'webreporting'},
    {name: 'deviceName', value: 'webreporting'},
  ];

  // private extraParam : string = "&udid=webreporting&deviceName=webreporting" ;

  private setBearer(): boolean {
    /*
        const token = window.localStorage.getItem('token')
        if (token) {
            const objToken = JSON.parse( token)
            api.defaults.headers.Authorization = `Bearer ${objToken.token}`
            return true
        }
        return false
        */
    const token = this.getBearerToken();

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
    return false;
  }

  private getGlobalTokenFromLocaleStorage(): any {
    const token = window.localStorage.getItem('token');

    if (token) {
      const objToken = JSON.parse(token);

      return objToken;
    }
    return null;
  }

  public getBearerToken(): string {
    const globalToken = this.getGlobalTokenFromLocaleStorage();

    if (!globalToken) {
      return '';
    }
    return globalToken.token;
  }

  public getAdditionalsParams(data?: string): string {
    if (!data) {
      data = '?';
    }
    this.additionalsParams.forEach(p => {
      data += `${data === '?' ? '' : '&'} ${p.name}=${p.value}`;
    });
    return data;
  }

  public getAdditionalsParamsObject(data?: object): object {
    if (!data) {
      data = {};
    }
    this.additionalsParams.forEach(p => {
      data[p.name] = p.value;
    });

    return data;
  }

  public get(url: string) {
    this.setBearer();
    return api.get(url);
  }

  public post(url: string, data: any, noBearer?: boolean, config?: any) {
    if (!noBearer) {
      this.setBearer();
    } // exception for login for example.,
    return api.post(
      url,
      data,
      config || {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
          crossDomain: true,
          Accept: '*',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Accept',
        },
      },
    );
  }

  public put(url: string, data: any, noBearer?: boolean) {
    if (!noBearer) {
      this.setBearer();
    } // exception for login for example.
    return api.put(url, data);
  }

  public delete(url: string, noBearer?: boolean) {
    if (!noBearer) {
      this.setBearer();
    } // exception for login for example.
    return api.delete(url);
  }

  public buildErrorDispatcher(errors: Array<any>): Array<any> {
    let error = '';
    errors.forEach(err => {
      for (const property in err) {
        if (error.length > 0) {
          error += ', ';
        }
        error += err[property];
      }
    });
    return [{error}];
  }

  public buildErrorFromResponse(errors: Array<any>): string {
    const error = this.buildErrorDispatcher(errors);

    return error[0].error;
  }

  public buildErrorAxios(error: AxiosError): Array<any> {
    return [{error: error.message, fullError: error}];
  }

  public buildMessageErrorAxios(error: AxiosError): string {
    return error.message;
  }

  public getDbTable(tableName: string): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.get(`${ENDPOINTS.DB.GETALLDATAFROMTABLE}/${tableName}`);
  }

  public getTablesList(): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.get(`${ENDPOINTS.DB.GETTABLESLIST}`);
  }

  public syncModel(modelName: string): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.post(`${ENDPOINTS.DB.SYNCMODEL}`, {modelName});
  }

  public restartAPI() {
    try {
      this.get(`${ENDPOINTS.RESTARTAPI}`);
    } catch (error) {
      /* empty */
    }
  }

  public postDbTable(
    tableName: string,
    data: any,
  ): Promise<AxiosResponse<APIResponse, APIResponse>> {
    if (data.id) {
      return this.put(ENDPOINTS.DB.PUT, {tableName, data});
    }
    return this.post(ENDPOINTS.DB.POST, {tableName, data});
  }

  public bulkDeleteDbTable(
    tableName: string,
    data: number[],
  ): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.post(ENDPOINTS.DB.DELETEBULK, {tableName, data});
  }
}

class AISDispatcherDataService extends AISDataService {
  // AFR-1810
  loginToDispatcher(corporateKey: string): any {
    return apiDispatcher.post(ENDPOINTS.DISPATCHER.CHECK, {
      customerKey: corporateKey,
    });
  }

  getEnvs(): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return apiDispatcher.get(ENDPOINTS.DISPATCHER.GET_ENVS);
  }

  getPinValues(): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return apiDispatcher.get(ENDPOINTS.DISPATCHER.GET_PINVALUES);
  }

  buildEnvsFormated(
    envsList: envsAttributes[],
    pinValuesList: pinvaluesAttributes[],
    customersList: CustomerAttributes[],
  ): any[] {
    envsList.forEach(env => {
      const pinValues = pinValuesList.filter(row => row.envid === env.id);

      pinValues.forEach(pin => {
        const customer = customersList.find(
          customer => customer.id === parseInt(pin.envcustomer, 10),
        );

        pin['customer'] = customer || {name: ` ${pin.envoaci} / Customer `};
      });
      env['corporateKeyDetail'] = pinValues;
    });
    return envsList.sort((env1, env2) => (env1.envname > env2.envname ? 1 : -1));
  }
}

class AISUserDataService extends AISDataService {
  async login2(login: string, password: string) {
    const response = await this.post(
      ENDPOINTS.LOGIN,
      this.getAdditionalsParamsObject({login, password}),
      true,
    );

    return response.data;
  }

  login(
    login: string,
    password: string,
    uuid?: string,
  ): Promise<AxiosResponse<APIResponse, APIResponse>> {
    if (uuid) {
      return this.post(ENDPOINTS.LOGIN_UUID, this.getAdditionalsParamsObject({uuid}), true);
    }

    return this.post(ENDPOINTS.LOGIN, this.getAdditionalsParamsObject({login, password}), true);
  }

  logout() {
    return this.post(
      ENDPOINTS.LOGOUT,
      this.getAdditionalsParamsObject({token: this.getBearerToken()}),
    );
  }

  check(): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.get(ENDPOINTS.CHECK + this.getAdditionalsParams());
  }

  checkApiConnection(): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.get(ENDPOINTS.CHECKAPICONNEXION);
  }

  getOneUser(idUser: number) {
    return this.post(ENDPOINTS.USERS.GETONE, this.getAdditionalsParamsObject({id: idUser}));
  }

  getAllUsers(): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.get(ENDPOINTS.USERS.GETALL + this.getAdditionalsParams());
  }

  createUser(user: any): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.post(ENDPOINTS.USERS.CREATEUSER, this.getAdditionalsParamsObject(user));
  }

  updateUser(user: any): Promise<AxiosResponse<APIResponse, APIResponse>> {
    //  router.post('/api/webreporting/user/edit', editUserInformations);
    return this.post(ENDPOINTS.USERS.UPDATEUSER, this.getAdditionalsParamsObject(user));
  }

  deleteUser(idUser: number) {
    return this.delete(ENDPOINTS.USERS.DELETEUSER + this.getAdditionalsParams(`/${idUser}`));
  }

  deleteUsers(idUsers: Array<number>) {
    return this.post(ENDPOINTS.USERS.DELETEUSERS, this.getAdditionalsParamsObject({idUsers}));
  }

  disableUsers(idUsers: Array<number>) {
    return this.post(ENDPOINTS.USERS.DISABLEDUSERS, this.getAdditionalsParamsObject({idUsers}));
  }

  updateListColumns(columnsSelected: HeadCell[]) {
    return this.post(
      ENDPOINTS.USERS.UPDATELISTCOLUMNS,
      this.getAdditionalsParamsObject({columnsSelected}),
    );
  }

  updateDashboardLayout(idUser, layout) {
    return this.post(
      ENDPOINTS.USERS.UPDATEDASHBORDLAYOUT,
      this.getAdditionalsParamsObject({idUser, layout}),
    );
  }

  updateDashboardPeriode(idUser, periode) {
    return this.post(
      ENDPOINTS.USERS.UPDATEDASHBORDPERIODE,
      this.getAdditionalsParamsObject({idUser, periode}),
    );
  }

  updateMaintenanceCriteria(idUser, criteria) {
    return this.post(
      ENDPOINTS.USERS.UPDATEMAINTENANCECRITERIA,
      this.getAdditionalsParamsObject({idUser, criteria}),
    );
  }

  getAuthUrlSSO(customer): string {
    if (customer === 'habile') {
      return 'https://fedhubrct.airfrance.fr/as/authorization.oauth2?client_id=client_ais&response_type=code&scope=openid&redirect_uri=https://api2746.airintservices.com/auth/habile/redirect_uri';
    }
    return `${api.getUri()}${ENDPOINTS.GETAUTHSSOURL}/${customer}`;
  }

  fakeRenderAuthenticated() {
    return `${api.getUri()}${ENDPOINTS.FAKE_RENDER_AUTHENTICATED}`;
  }

  setFrontUrlInSession(origin) {
    return this.post(ENDPOINTS.SET_ORIGIN_IN_SESSION, this.getAdditionalsParamsObject({origin}));
  }

  updateUserSessionProfile(newProfile: number) {
    return this.post(
      ENDPOINTS.USERS.UPDATEUSERSESSIONPROFILE,
      this.getAdditionalsParamsObject({newProfile}),
    );
  }

  updatePNFavorites(idUser, favorites) {
    return this.post(
      ENDPOINTS.USERS.UPDATE_PN_FAVORITES,
      this.getAdditionalsParamsObject({idUser, favorites}),
    );
  }
}

class AISReportDataService extends AISDataService {
  getAllReports(): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.get(
      ENDPOINTS.WEBREPORTING.REPORTS.GETRFIDINVENTORIES + this.getAdditionalsParams(),
    );
  }
}

class AISPermissionsDataService extends AISDataService {
  getConfig() {
    return this.get(ENDPOINTS.PERMISSIONS.GETPERMISSIONSCONFIG + this.getAdditionalsParams());
  }
  createProfile(profile: any): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.post(ENDPOINTS.PERMISSIONS.CREATEPROFILE, this.getAdditionalsParamsObject(profile));
  }
  updateProfile(profile: any): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.post(ENDPOINTS.PERMISSIONS.UPDATEPROFILE, this.getAdditionalsParamsObject(profile));
  }
  getOneProfile(idProfile: number) {
    return this.post(
      ENDPOINTS.PERMISSIONS.GETONE,
      this.getAdditionalsParamsObject({id: idProfile}),
    );
  }
  deleteProfile(idProfile: number) {
    return this.delete(
      ENDPOINTS.PERMISSIONS.DELETEPROFILE + this.getAdditionalsParams(`/${idProfile}`),
    );
  }
  deleteProfileFeature(idProfile: number, idFeature: number) {
    return this.post(
      ENDPOINTS.PERMISSIONS.DELETEPROFILEFEATURE,
      this.getAdditionalsParamsObject({
        idProfile,
        idFeature,
      }),
    );
  }
}

class AISDevicesDataService extends AISDataService {
  getAllDevices() {
    return this.get(ENDPOINTS.DEVICES.GETALL + this.getAdditionalsParams());
  }

  deleteDevice(idDevice: number) {
    return this.post(ENDPOINTS.DEVICES.DELETE, this.getAdditionalsParamsObject({id: idDevice}));
  }
  activeDevice(idDevice: number) {
    return this.post(ENDPOINTS.DEVICES.ACTIVE, this.getAdditionalsParamsObject({id: idDevice}));
  }

  changeStatus(idDevice: number, newStatus: number) {
    return this.post(
      ENDPOINTS.DEVICES.CHANGESTATUS,
      this.getAdditionalsParamsObject({id: idDevice, status: newStatus}),
    );
  }
}

class AISDocumentsDataService extends AISDataService {
  getAllDocuments() {
    return this.get(ENDPOINTS.DOCUMENTS.GETALL + this.getAdditionalsParams());
  }

  updateRevision(revision: any) {
    return this.put(ENDPOINTS.DOCUMENTS.UPDATEREVISION, this.getAdditionalsParamsObject(revision));
  }
}

class AISDocumentsDataSubmissionService extends AISDataService {
  getAllEquipmentTypeAndCMM() {
    return this.get(ENDPOINTS.DOCUMENTS.POSTUPLOADCMM + this.getAdditionalsParams());
  }
}

class AISHistoryDataService extends AISDataService {
  getHistory(limit?: number) {
    return this.post(
      ENDPOINTS.HISTORY.GETHISTORY,
      this.getAdditionalsParamsObject(limit ? {limit} : null),
    );
  }
}

class AISUplaodCMMDataService extends AISDataService {
  getListUpload() {
    return this.get(ENDPOINTS.DOCUMENTS.LISTUPLOADCMM + this.getAdditionalsParams());
  }

  submitTechnicalDoc(data: any, onProgress: Function, onTerminate: Function, onFailed: Function) {
    api
      .postForm(ENDPOINTS.DOCUMENTS.POSTUPLOADCMM, data, {
        onUploadProgress: ProgressEvent => {
          onProgress(Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100));
        },
      })
      .then(res => {
        onTerminate(res);
      })
      .catch(error => {
        onFailed(error);
      });
  }

  getDetail(idUpload: number) {
    return this.post(ENDPOINTS.DOCUMENTS.GETONE, this.getAdditionalsParamsObject({id: idUpload}));
  }

  getFile(filePath: string, fileType: string, onTerminate: Function, onFailed: Function) {
    this.post(
      ENDPOINTS.DOCUMENTS.GETFILE,
      this.getAdditionalsParamsObject({file: filePath}),
      false,
      {responseType: 'arraybuffer'},
    )
      .then(response => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: fileType,
        });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        // Open the URL on new Window
        onTerminate(fileURL);
      })
      .catch(error => {
        console.error(error);
        onFailed(error);
      });
  }
}

class AISNotificationDataService extends AISDataService {
  private storeNotification: Promise<AxiosResponse<APIResponse, APIResponse>> = null;

  private storeDeleteNotif(revision: number, nonce: string) {
    if (!this.storeNotification) {
      return;
    }

    this.storeNotification.then(res => {
      res.data.results = res.data.results.filter(
        notif => notif.nonce !== nonce && notif.revision !== revision,
      );
    });
  }

  private storeAddNotif(newNotif) {
    if (!this.storeNotification) {
      return;
    }

    const data = {
      nonce: newNotif.nonce,
      title: newNotif.data.title,
      date: newNotif.data.date_send,
      subtitle: newNotif.data.subtitle,
      body: newNotif.data.body,
      revision: newNotif.newIdRevisionIndex,
      countUser: newNotif.countUser,
      countRead: newNotif.countRead,
    };

    this.storeNotification.then(res => {
      if (res && res.data && res.data.results) {
        res.data.results.unshift(data);
      }
    });
  }

  getListNotification() {
    if (this.storeNotification) {
      return this.storeNotification;
    }
    this.storeNotification = this.get(ENDPOINTS.NOTIFICATIONS.LIST + this.getAdditionalsParams());
    return this.storeNotification;
  }

  createNotification(data: any): Promise<AxiosResponse<APIResponse, APIResponse>> {
    const notif = this.post(ENDPOINTS.NOTIFICATIONS.CREATE, this.getAdditionalsParamsObject(data));

    notif.then(newNotif => {
      if (newNotif.data && newNotif.data.results && newNotif.data.results.length > 0) {
        const dataNotif = newNotif.data.results[0];

        dataNotif['countUser'] = dataNotif['countUsers'];
        dataNotif['countRead'] = 0;
        this.storeAddNotif(dataNotif);
      }
    });

    return notif;
  }

  deleteNotification(
    revision: number,
    nonce: string,
  ): Promise<AxiosResponse<APIResponse, APIResponse>> {
    this.storeDeleteNotif(revision, nonce);

    return this.post(
      ENDPOINTS.NOTIFICATIONS.DELETE,
      this.getAdditionalsParamsObject({revision, nonce}),
    );
  }
}

class AISKpiDataService extends AISDataService {
  getCounters() {
    return this.get(ENDPOINTS.KPIS.COUNTERS + this.getAdditionalsParams());
  }
}

class AISTablesDataService extends AISDataService {
  getListAircraft() {
    return this.get(ENDPOINTS.TABLES.AIRCRAFT.LIST + this.getAdditionalsParams());
  }

  updateAircraft(aircraft: Aircraft): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.post(ENDPOINTS.TABLES.AIRCRAFT.UPDATE, this.getAdditionalsParamsObject({aircraft}));
  }

  updateKpi(kpi: KpiOptionsAttributes): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.post(ENDPOINTS.TABLES.KPIS.UPDATE, this.getAdditionalsParamsObject({kpi}));
  }

  getListKpi() {
    return this.get(ENDPOINTS.TABLES.KPIS.LIST + this.getAdditionalsParams());
  }

  getDataMappingAircraftTypeNarrowWideBodies() {
    return this.get(
      ENDPOINTS.TABLES.DATAMAPPING.LIST_NARROW_WIDE_BODIES + this.getAdditionalsParams(),
    );
  }

  updateRowDataMapping(
    row: DataMappingAttributes,
  ): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.post(
      ENDPOINTS.TABLES.DATAMAPPING.UPDATEROW,
      this.getAdditionalsParamsObject({row}),
    );
  }
}

class AISSettingsDataService extends AISDataService {
  getCustomerOptions() {
    return this.get(ENDPOINTS.GENERALSETTINGS.GETCUSTOMEROPTIONS + this.getAdditionalsParams());
  }

  updateCustomerOptions(settings: Array<any>) {
    return this.post(ENDPOINTS.GENERALSETTINGS.UPDATE, this.getAdditionalsParamsObject({settings}));
  }

  getTheme(customerId: number) {
    return this.post(
      ENDPOINTS.GENERALSETTINGS.GETTHEME,
      this.getAdditionalsParamsObject({customerId}),
    );
  }

  setTheme(theme: cabinAppTheme) {
    return this.post(ENDPOINTS.GENERALSETTINGS.SETTHEME, this.getAdditionalsParamsObject(theme));
  }

  getSSOConfig(customerId: number) {
    const apiURL = api.getUri();
    const env = AISCacheService.getEnvironment();

    return this.post(
      ENDPOINTS.GENERALSETTINGS.GET_SSO_CONFIG,
      this.getAdditionalsParamsObject({customerId, env, apiURL}),
    );
  }

  setSSOConfig(config: IConfigSSO) {
    return this.post(
      ENDPOINTS.GENERALSETTINGS.SET_SSO_CONFIG,
      this.getAdditionalsParamsObject(config),
    );
  }
}

class AISCustomerInteropDataService extends AISDataService {
  getCustomerInterop() {
    return this.get(ENDPOINTS.CUSTOMERINTEROP.LIST + this.getAdditionalsParams());
  }

  updateCustomerOptions(settings: Array<any>) {
    return this.post(ENDPOINTS.GENERALSETTINGS.UPDATE, this.getAdditionalsParamsObject({settings}));
  }

  getOneById(customerInteropId: number) {
    return this.post(
      ENDPOINTS.CUSTOMERINTEROP.GETONEBYID,
      this.getAdditionalsParamsObject({id: customerInteropId}),
    );
  }

  updateCustomerInterop(data) {
    return this.post(ENDPOINTS.CUSTOMERINTEROP.UPDATE, this.getAdditionalsParamsObject(data));
  }

  deleteCustomerInterop(id: number) {
    return this.delete(ENDPOINTS.CUSTOMERINTEROP.DELETE + this.getAdditionalsParams(`/${id}`));
  }

  getMaxIdList() {
    return this.get(ENDPOINTS.CUSTOMERINTEROP.GETMAXIDLIST + this.getAdditionalsParams());
  }
}

class AISEnvsVarDataService extends AISDataService {}

class AISWebReportingDataService extends AISDataService {
  getAvailableFilters() {
    return this.get(ENDPOINTS.WEBREPORTING.GETAVAILABLEFILTERS + this.getAdditionalsParams());
  }

  getMaintenanceData(criteria: ICriteria) {
    return this.post(
      ENDPOINTS.WEBREPORTING.MAINTENANCE.GETDATA,
      this.getAdditionalsParamsObject(criteria),
    );
  }

  getCounters(periode) {
    return this.post(
      ENDPOINTS.WEBREPORTING.DASHBOARD.COUNTERS,
      this.getAdditionalsParamsObject({periode}),
    );
  }

  calculKpis(periode: string) {
    return this.post(
      ENDPOINTS.WEBREPORTING.DASHBOARD.CALCULATE,
      this.getAdditionalsParamsObject({periode}),
    );
  }

  calculKpisCRON() {
    return this.get(ENDPOINTS.WEBREPORTING.DASHBOARD.CALCULATECRON + this.getAdditionalsParams());
  }

  getKpisResults(periode: string) {
    return this.post(
      ENDPOINTS.WEBREPORTING.DASHBOARD.GETRESULTS,
      this.getAdditionalsParamsObject({periode}),
    );
  }

  getWeeklyReportResults(periode: string) {
    return this.post(
      ENDPOINTS.WEBREPORTING.REPORTS.GETWEEKLYREPORT,
      this.getAdditionalsParamsObject({periode}),
    );
  }

  updateKpi(kpi: KpiOptions): Promise<AxiosResponse<APIResponse, APIResponse>> {
    return this.post(ENDPOINTS.TABLES.KPIS.UPDATE, this.getAdditionalsParamsObject({kpi}));
  }

  getAircraftList() {
    return this.get(ENDPOINTS.WEBREPORTING.FLEET.AIRCRAFTLIST + this.getAdditionalsParams());
  }

  searchPartNumber(searchTerm: string) {
    return this.post(
      ENDPOINTS.WEBREPORTING.SEARCHPN.SEARCHTERM,
      this.getAdditionalsParamsObject({searchTerm}),
    );
  }

  searchRequestsByPartNumber(listPn: any[], year?: number) {
    return this.post(
      ENDPOINTS.WEBREPORTING.SEARCHPN.GETRESULT,
      this.getAdditionalsParamsObject({listPn, year}),
    );
  }

  searchPNbyAISFavoris(code: string) {
    return this.post(
      ENDPOINTS.WEBREPORTING.SEARCHPN.GETRESULTBYCODE,
      this.getAdditionalsParamsObject({code}),
    );
  }
}

class AISRequestDataService extends AISDataService {
  getRequest(id: number) {
    return this.post(ENDPOINTS.REQUEST.READ, this.getAdditionalsParamsObject({id}));
  }

  buildRequestPDF(ids: number[]) {
    return this.post(ENDPOINTS.REQUEST.BUILDPDF, this.getAdditionalsParamsObject({ids}), false);
  }

  getRequestPdf(filePath: string) {
    return this.post(ENDPOINTS.REQUEST.GETPDF, this.getAdditionalsParamsObject({filePath}), false, {
      responseType: 'blob',
    });
  }

  checkAnomalieRequest(id: number) {
    return this.post(ENDPOINTS.REQUEST.CHECKANOMALIE, this.getAdditionalsParamsObject({id}), false);
  }

  modifyRequests(changes: IfieldsToUpdate[]) {
    return this.post(ENDPOINTS.REQUEST.MODIFYREQUESTS, this.getAdditionalsParamsObject({changes}));
  }

  getFlattenRequestsByIds(idsRequest: number[]) {
    return this.post(
      ENDPOINTS.REQUEST.GETREQUESTFLATTEN,
      this.getAdditionalsParamsObject({idsRequest}),
    );
  }
}

class AISBucketDataService extends AISDataService {
  getFile(param: object | Array<object>): object {
    return this.post(ENDPOINTS.BUCKET.LOADFILEPATH, this.getAdditionalsParamsObject(param));
  }
}

class AISAdminToolsDataService extends AISDataService {
  getStoredRequestInterop(periode: string = 'm-12', forceRecalcul: boolean = false) {
    return this.post(
      ENDPOINTS.DB.GETSTOREDREQUESTINTEROP,
      this.getAdditionalsParamsObject({periode, forceRecalcul}),
    );
  }
}

export const AISUserService = new AISUserDataService();
export const AISPermissionsService = new AISPermissionsDataService();
export const AISDevicesService = new AISDevicesDataService();
export const AISDocumentsService = new AISDocumentsDataService();
export const AISDocumentsServiceSubmission = new AISDocumentsDataSubmissionService();
export const AISHistoryService = new AISHistoryDataService();
export const AISUplaodCMMService = new AISUplaodCMMDataService();
export const AISNotificationService = new AISNotificationDataService();
export const AISKpiService = new AISKpiDataService();
export const AISTablesService = new AISTablesDataService();
export const AISSettingsService = new AISSettingsDataService();
export const AISCustomerInteropService = new AISCustomerInteropDataService();
export const AISDispatcherService = new AISDispatcherDataService();
export const AISEnvsVarService = new AISEnvsVarDataService();
export const AISWebReportingService = new AISWebReportingDataService();
export const AISRequestService = new AISRequestDataService();
export const AISReportService = new AISReportDataService();
export const AISBucketService = new AISBucketDataService();
export const AISAdminToolsService = new AISAdminToolsDataService();
