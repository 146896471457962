
import Axios from "axios";
import {MODE_DEV_API_DISPATCHER} from 'src/config/mode-development';
import { AISCacheService } from "./cacheService";
import {DISPATCHER_URL} from '../../config/env-backoffice';

const url = AISCacheService.getApiValueFromDispatcher() || "API URL NOT FOUND"
const urls = {
  test: url,
  development: url,
  production: url,
};


const urlsDispatcher = {
  test: String(DISPATCHER_URL) !== '${DISPATCHER_URL}' ? DISPATCHER_URL : MODE_DEV_API_DISPATCHER,
  development:
    String(DISPATCHER_URL) !== '${DISPATCHER_URL}' ? DISPATCHER_URL : MODE_DEV_API_DISPATCHER,
  production:
    String(DISPATCHER_URL) !== '${DISPATCHER_URL}' ? DISPATCHER_URL : MODE_DEV_API_DISPATCHER,
};



const api = Axios.create({
  baseURL: urls[process.env.NODE_ENV],
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    crossDomain: true,
    Accept: '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Accept',
  },
});

export default api;

export const apiDispatcher = Axios.create({
  baseURL: urlsDispatcher[process.env.NODE_ENV],
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    crossDomain: true,
    Accept: '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Accept',
  },
});

export const ENDPOINTS = {
  DISPATCHER: {
    CHECK: 'customer/check',
    GET_ENVS: 'database/envs',
    GET_PINVALUES: 'database/pinvalues',
  },
  DB: {
    GETALLDATAFROMTABLE: 'db/getalldata',
    GETTABLESLIST: 'db/getTablesList',
    SYNCMODEL: 'db/sync-model',
    POST: 'db/post',
    PUT: 'db/put',
    DELETEBULK: 'db/deletebulkbyids',
    GETSTOREDREQUESTINTEROP: 'db/getStoredRequestInterop',
  },
  LOGIN: 'auth/login-portal',
  LOGIN_UUID: 'auth/login-portal-uuid',
  FAKE_RENDER_AUTHENTICATED: 'auth/fake/render-authenticated',
  LOGOUT: 'auth/logout',
  GETAUTHSSOURL: 'auth/redirect-sso-url',
  CHECK: 'auth/check',
  CHECKAPICONNEXION: 'auth/checkapi',
  RESTARTAPI: 'web-admin/restart',
  SET_ORIGIN_IN_SESSION: 'web-admin/setOriginUrl',
  USERS: {
    GETONE: 'web-admin/users/edit',
    GETALL: 'web-admin/users/allusers',
    CREATEUSER: 'web-admin/users/create',
    UPDATEUSER: 'web-admin/users/update',
    DELETEUSER: 'web-admin/users/delete',
    DELETEUSERS: 'web-admin/users/deleteUsers',
    DISABLEDUSERS: 'web-admin/users/disableUsers',
    UPDATELISTCOLUMNS: 'web-admin/users/updateListColumns',
    UPDATEDASHBORDLAYOUT: 'web-admin/users/updateDashboardLayout',
    UPDATEDASHBORDPERIODE: 'web-admin/users/updateDashboardPeriode',
    UPDATEMAINTENANCECRITERIA: 'web-admin/users/updateMaintenanceCriteria',
    UPDATEUSERSESSIONPROFILE: 'web-admin/users/updateUserSessionProfile',
    UPDATE_PN_FAVORITES: 'web-admin/users/updatePNFavorite',
  },
  PERMISSIONS: {
    GETPERMISSIONSCONFIG: 'web-admin/permissions',
    CREATEPROFILE: 'web-admin/permissions/createprofile',
    UPDATEPROFILE: 'web-admin/permissions/updateprofile',
    DELETEPROFILE: 'web-admin/permissions/deleteprofile',
    DELETEPROFILEFEATURE: 'web-admin/permissions/deletefeatureprofile',
    GETONE: 'web-admin/permissions/edit',
  },
  DEVICES: {
    GETALL: 'web-admin/devices/alldevices',
    DELETE: 'web-admin/devices/delete',
    ACTIVE: 'web-admin/devices/active',
    CHANGESTATUS: 'web-admin/devices/changestatus',
  },
  DOCUMENTS: {
    GETALL: 'web-admin/documents/alldocuments',
    UPDATEREVISION: 'web-admin/documents/updaterevision',
    POSTUPLOADCMM: 'web-admin/documents/uploadCMM',
    LISTUPLOADCMM: 'web-admin/documents/listUploadCMM',
    GETONE: 'web-admin//documents/getUploadDetail',
    GETFILE: 'web-admin//documents/getUploadFile',
  },
  HISTORY: {
    GETHISTORY: 'web-admin/history/gethistory',
  },
  NOTIFICATIONS: {
    LIST: 'web-admin/notification/list',
    CREATE: 'web-admin/notification/create',
    DELETE: 'web-admin/notification/delete',
  },
  KPIS: {
    COUNTERS: 'web-admin/kpis/counters',
  },
  TABLES: {
    AIRCRAFT: {
      LIST: 'web-admin/tables/aircrafts',
      UPDATE: 'web-admin/tables/aircrafts/update',
    },
    KPIS: {
      LIST: 'web-admin/tables/kpis',
      UPDATE: 'web-admin/tables/kpis/update',
    },
    DATAMAPPING: {
      UPDATEROW: 'web-admin/tables/datamapping/update-row',
      LIST_NARROW_WIDE_BODIES: 'web-admin/tables/datamapping/narrow-wide-bodies',
    },
  },
  GENERALSETTINGS: {
    GETCUSTOMEROPTIONS: 'web-admin/settings/listCustomerOptions',
    UPDATE: 'web-admin/settings/update',
    GETTHEME: 'web-admin/settings/getTheme',
    SETTHEME: 'web-admin/settings/setTheme',
    GET_SSO_CONFIG: 'web-admin/settings/getSSOConfig',
    SET_SSO_CONFIG: 'web-admin/settings/setSSOConfig',
  },
  CUSTOMERINTEROP: {
    LIST: 'web-admin/customer-interop/listCustomerInterop',
    GETONEBYID: 'web-admin/customer-interop/getOneById',
    UPDATE: 'web-admin/customer-interop/update',
    DELETE: 'web-admin/customer-interop/delete',
    GETMAXIDLIST: 'web-admin/customer-interop/getMaxIdList',
  },
  WEBREPORTING: {
    GETAVAILABLEFILTERS: 'web-reporting/getAvailableFilters',
    DASHBOARD: {
      COUNTERS: 'web-reporting/dashboard/counters',
      CALCULATE: 'web-reporting/kpis/calculateKpis',
      GETRESULTS: 'web-reporting/kpis/getKpiResults',
      CALCULATECRON: 'web-reporting/kpis/cron-calculateKpisIfNeeded',
    },
    MAINTENANCE: {
      GETDATA: 'web-reporting/getMaintenanceData',
    },
    REPORTS: {
      GETRFIDINVENTORIES: 'web-reporting/getRFIDInventories',
      GETWEEKLYREPORT: 'web-reporting/kpis/getWeeklyReport',
    },
    FLEET: {
      AIRCRAFTLIST: 'web-reporting/fleet/getAircraftList',
    },
    SEARCHPN: {
      SEARCHTERM: 'web-reporting/search-pn/search-term',
      GETRESULT: 'web-reporting/search-pn/getResultByPNs',
      GETRESULTBYCODE: 'web-reporting/search-pn/getResultByCode',
    },
  },
  REQUEST: {
    READ: 'web-reporting/request/getDetailRequest',
    MODIFYREQUESTS: 'web-reporting/request/modifyRequests',
    BUILDPDF: 'web-reporting/request/buildDetailRequestPdf',
    GETPDF: 'web-reporting/request/getDetailRequestPdf',
    CHECKANOMALIE: 'web-reporting/request/checkAnomalieRequest',
    GETREQUESTFLATTEN: 'web-reporting/request/getFlattenRequestsByIds',
  },
  BUCKET: {
    LOADFILEPATH: 'bucket/loadFilePath',
    DOWNLOATMPDFILE: 'bucket/downloadTmpFile',
    UPLOADFILE: 'bucket/uploadFile',
  },
};


/*
routerWebAdmin.get('/users/allusers', controller.getAllUsersForCustomer);
routerWebAdmin.post('/users/edit', controller.getOneUserForCustomer);
routerWebAdmin.post('/users/create', controller.createUser);
*/

// login   : svaairint
// password : "noorokirf"
// https://api.airintservices.com/production/4-0-0/api/webreporting/users?page=1&udid=webreporting&deviceName=webreporting&